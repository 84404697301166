import * as React from 'react';
import {AppToolsScreen, ComponentSync, LL_H_C, ModuleFE_ActionProcessor, TS_AppTools, Button, TS_PropRenderer, TS_Input} from '@nu-art/thunderstorm/frontend';
import {ActionKey_ParseSnomedCSV} from '@app/hcs';
import {ModuleFE_Reference} from '@app/hcs/frontend';

type State = {
	searchTerm?: string;
};

class DevPage_SnomedDictionaryActions_Renderer
	extends ComponentSync<{}, State> {

	render() {
		return <div className={'collection-upgrades-page'}>
			{TS_AppTools.renderPageHeader('Snomed Dictionary Actions')}
			<LL_H_C className={'buttons-container'}>
				{this.renderParseCSVButton()}
			</LL_H_C>
			{this.render_SnomedScraping()}
		</div>;
	}

	renderParseCSVButton = () => {
		return <Button
			variant={'primary'}
			key={'parse-csv-button'}
			onClick={async () => {
				await ModuleFE_ActionProcessor.vv1.execute({key: ActionKey_ParseSnomedCSV}).executeSync();
			}}
		>button</Button>;
	};

	private render_SnomedScraping = () => {
		return <TS_PropRenderer.Vertical label={'Scrape by search term'} style={{padding: '20px'}}>
			<TS_Input type={'text'} value={this.state.searchTerm} onChange={v => this.setState({searchTerm: v})}/>
			<Button onClick={async () => {
				const term = this.state.searchTerm;
				if (!term || term.length < 3)
					return;

				const response = await ModuleFE_Reference._snomed.scrapeBySearchTerm({term}).executeSync();
				console.table(response.results);
			}}>Scrape</Button>
		</TS_PropRenderer.Vertical>;
	};
}

export const ATS_SnomedDictionaryActions: AppToolsScreen = {
	name: 'Snomed Dictionary Actions',
	key: 'snomed-dictionary-actions',
	renderer: DevPage_SnomedDictionaryActions_Renderer,
	group: 'Quai Dev Tools',
};