/*
 * A typescript & react boilerplate with api call example
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import {LoggedStatus, ModuleFE_Account, OnLoginStatusUpdated} from '@nu-art/user-account/frontend';
import {
	ModuleFE_Dialog,
	ModuleFE_ServerInfo,
	ModuleFE_SyncManager,
	ModuleFE_Thunderstorm,
	StorageKey,
	Thunder,
	ThunderDispatcher,
	TS_Route
} from '@nu-art/thunderstorm/frontend';
import {arrayToMap, BadImplementationException, Module} from '@nu-art/ts-common';
import {Component_HealthcareSpace} from '@app/hcs/frontend';
import {Page_DiseaseProfile} from '@app/dp/frontend';
import {Panel_ResourceManager_V1_old} from '@km/managers/ResourceEditor';
import {QuaiIconsType} from '@app/styles';
import {Page_OrganizationEditor} from '@app/org/frontend';
import {Page_ResourceEditor_V2} from '@app/hcs/frontend/ui/Page_ResourceEditor/Page_ResourceEditor_V2';
import {Page_PathwayEditor} from '@app/pathway/frontend';
import {Manager_Permissions} from '@km/managers/Manager_Permissions/Manager_Permissions';
import {Page_ExpressionManager} from '@app/hcs/frontend/ui/expressions/ui/expression-manager/Page_ExpressionManager';
import {Component_SearchV2} from '../../knowledge-manager/components/search/Component_SearchV2';
import {Route_AdvisorContextEditor} from '@app/advisor-content/frontend/ui/advisor-context-editor/consts';
import {ModuleFE_DefaultPermissions, PermissionKey_FE} from '@nu-art/permissions/frontend/PermissionKey_FE';
import {ArtifactsPipelinePermissionKeysNames} from '@app/shared/permission-manager-ui-permissions';
import {Route_FHIRManager} from '@app/fhir/frontend/ui/Page_FHIRManager/consts';
import {Route_AdvisorUIEditor} from '@app/advisor-ui/frontend/ui/Page_AdvisorUI/consts';
import {Route_AvatarMode} from '@app/advisor-ui/frontend';
import {Route_ActionManager} from '@app/pathway/frontend/ui/Page_OrderEditor/consts';
import {Route_Page_SmartDocs} from '@app/advisor-content/frontend/ui/pages/Page_SmartDocs/route';
import {Page_ContextRulesManager} from '@app/hcs/frontend/ui/context-rules/Page_ContextRulesManager';
import {ModuleFE_ConflictResolution} from '@nu-art/conflict-resolution/frontend/_modules/ModuleFE_ConflictResolution';

export interface ToggleSideNavBarStateListener {
	__onToggleSideNavBarState: () => void;
}

export const dispatch_onToggleSideNavBarState = new ThunderDispatcher<ToggleSideNavBarStateListener, '__onToggleSideNavBarState'>('__onToggleSideNavBarState');

export type RouteDomainDeclaration = {
	route: TS_Route
	icon: keyof QuaiIconsType; // 'pathway'
	routeTitle: string // 'Pathway Manager'
	designator?: string; // 'V2'
}
const RouteDomainsArray: RouteDomainDeclaration[] = [
	{
		route: Component_SearchV2.Route,
		icon: 'search',
		routeTitle: 'Search',
	},
	{
		icon: 'healthcareSpace',
		route: Component_HealthcareSpace.Route,
		routeTitle: 'Healthcare Space',
	},
	{
		route: Page_DiseaseProfile.Route,
		icon: 'diseaseProfile',
		routeTitle: 'Disease Profile',
	},
	{
		route: Page_PathwayEditor.Route,
		icon: 'pathways',
		routeTitle: 'Pathway Manager',
	},
	{
		route: Page_ContextRulesManager.Route,
		icon: 'list',
		routeTitle: 'Context Rules Manager',
	},
	{
		route: Route_Page_SmartDocs,
		icon: 'fillForm',
		routeTitle: 'Smart Docs'
	},
	{
		route: Page_ExpressionManager.Route,
		icon: 'expressions',
		routeTitle: 'Expression Manager',
	},
	{
		route: Panel_ResourceManager_V1_old.Route,
		icon: 'resources',
		routeTitle: 'Resource Manager',
		designator: 'RES',
	},
	{
		route: Page_ResourceEditor_V2.Route,
		icon: 'resources',
		routeTitle: 'Resource Manager V2',
		designator: 'v2',
	},
	{
		route: Page_OrganizationEditor.Route,
		icon: 'organization',
		routeTitle: 'Organization Manager V2',
		designator: 'v2',
	},
	{
		route: Route_ActionManager,
		icon: 'resources',
		routeTitle: 'Order Manager',
	},
	{
		route: Manager_Permissions.Route,
		icon: 'userPermissions',
		routeTitle: 'Permissions Manager',
	},
	{
		route: Route_AdvisorContextEditor,
		routeTitle: 'Advisor Context Manager V2',
		icon: 'newAdvisorForm',
		designator: 'V2',
	},
	{
		route: Route_AdvisorUIEditor,
		routeTitle: 'Advisor UI Manager',
		icon: 'advisor_v5_relatedFindings',
	},
	{
		route: Route_FHIRManager,
		routeTitle: 'FHIR Manager',
		icon: 'advisor_v5_settings'
	},
	{
		route: Route_AvatarMode,
		routeTitle: 'Avatar Mode',
		icon: 'users',
	}
];
export const RouteDomainsMap = arrayToMap(RouteDomainsArray, item => item.route.key);

export class ModuleFE_AppModule_Class
	extends Module
	implements OnLoginStatusUpdated {

	readonly permissions: ModuleFE_DefaultPermissions<typeof ArtifactsPipelinePermissionKeysNames, {}>;

	async __onLoginStatusUpdated() {
		const status = ModuleFE_Account.getLoggedStatus();
		switch (status) {
			case LoggedStatus.SESSION_TIMEOUT:
			case LoggedStatus.LOGGED_OUT:
				ModuleFE_ServerInfo.stopListening();
				ModuleFE_SyncManager.stopListening();
				ModuleFE_SyncManager.cancelSync();
				ModuleFE_Dialog.close();
				await ModuleFE_Thunderstorm.clearWebsiteData();
				break;
			case LoggedStatus.VALIDATING:
				break;
			case LoggedStatus.LOGGED_IN:
				ModuleFE_SyncManager.startListening();
				ModuleFE_ServerInfo.startListening();
				break;
			default:
				throw new BadImplementationException(`logged status can only be one of 'LOGGED_IN', 'LOGGED_OUT' or 'VALIDATING'`);
		}
	}


	constructor() {
		super();
		this.permissions = {
			ui: PermissionKey_FE.generatePermissionKeysByLevels(ArtifactsPipelinePermissionKeysNames),
			collection: {}
		};
	}

//NavBar Related Things
	public getNavBarStatus(): boolean {
		return this.navBarStatus.get(false);
	}

	private navBarStatus: StorageKey<boolean> = new StorageKey<boolean>('nav-bar__is-open');

	protected init(): void {
		this.navBarStatus.set(false);
		ModuleFE_ConflictResolution.initDefaultHasDependencyResponse();
	}

	toggleSideNavBar() {
		const lastNavBarStatus = this.navBarStatus.get();
		this.navBarStatus.set(!lastNavBarStatus);
		dispatch_onToggleSideNavBarState.dispatchUI();
	}

	getEnv() {
		return Thunder.getInstance().getConfig().label; // LOCAL/DEV/STAGING/ empty string for prod
	}
}

export const ModuleFE_AppModule = new ModuleFE_AppModule_Class();
