/*
 * A typescript & react boilerplate with api call example
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from 'react';
import {_className, AppPage, LL_H_C, LL_V_L, Button, TS_ComponentTransition} from '@nu-art/thunderstorm/frontend';
import {Component_GoogleSAMLLogin, Component_Login, Component_Register} from '@nu-art/user-account/frontend';
import './Page_Login.scss';
import {ICONS} from '@app/styles';

type Props = {}
type State = { register: boolean }

export class Page_Login
	extends AppPage<Props, State> {

	constructor(props: any) {
		super(props);
		this.state = {register: false};
	}

	protected deriveStateFromProps(nextProps: Props, state: State): State {
		return state;
	}

	// ######################### Render Background #########################

	private renderBackground = () => {
		return <div id={'login-background'}>
			<div className={'box box-1'}/>
			<div className={'box box-2'}/>
			<div className={'blur-backdrop'}/>
		</div>;
	};

	// ######################### Render Peeper #########################

	private renderSwitchToRegister = () => {
		const className = _className('page-login__switch-to-register', this.state.register && 'hidden');
		return <LL_V_L className={className}>
			<h1>New Here?</h1>
			<h2>Sign up to use our Knowledge Manager</h2>
			<Button variant={'primary'} onClick={() => this.setState({register: true})}>Sign Up</Button>
		</LL_V_L>;
	};

	private renderSwitchToLogin = () => {
		const className = _className('page-login__switch-to-login', !this.state.register && 'hidden');
		return <LL_V_L className={className}>
			<h1>Already Registered?</h1>
			<h2>Login to use our Knowledge Manager</h2>
			<Button variant={'primary'} onClick={() => this.setState({register: false})}>Login</Button>
		</LL_V_L>;
	};

	private renderPeeper = () => {
		return <div className={'page-login__peeper'}>
			{this.renderSwitchToLogin()}
			{this.renderSwitchToRegister()}
		</div>;
	};

	// ######################### Render Login Area #########################

	private renderLoginContainer = () => {
		const hidden = this.state.register;
		const className = _className('page-login__form-container', hidden && 'hidden');
		return <div className={className}>
			<TS_ComponentTransition
				trigger={!hidden} // Hidden means the contents are gone, not just hidden
				transitionTimeout={300}
				mountTimeout={300}
				skipAnimationOnMount={true}
			>
				{this.renderLoginForm()}
			</TS_ComponentTransition>
		</div>;
	};

	private renderLoginForm = () => {
		return <LL_V_L className={'page-login__login-form'}>
			<h1>Login to Your Account</h1>
			<Component_Login/>
			<div className={'page-login__login-form__separator'}/>
			<Component_GoogleSAMLLogin text={'Login With Google'}/>
		</LL_V_L>;
	};

	// ######################### Render Register Area #########################

	private renderRegisterContainer = () => {
		const hidden = !this.state.register;
		const className = _className('page-login__form-container', hidden && 'hidden');
		return <div className={className}>
			<TS_ComponentTransition
				trigger={!hidden} // Hidden means the contents are gone, not just hidden
				transitionTimeout={300}
				mountTimeout={300}
				skipAnimationOnMount={true}
			>
				{this.renderRegisterForm()}
			</TS_ComponentTransition>
		</div>;
	};

	private renderRegisterForm = () => {
		return <LL_V_L className={'page-login__login-form'}>
			<h1>Register Your Account</h1>
			<Component_Register renderPasswordRules={true}/>
		</LL_V_L>;
	};

	// ######################### Render #########################

	render() {
		return <LL_H_C id={'page-login'}>
			{this.renderBackground()}
			<LL_H_C className={'match_parent'}>
				{this.renderLoginContainer()}
				{this.renderPeeper()}
				{this.renderRegisterContainer()}
			</LL_H_C>
			{this.renderQuaiBanner()}
		</LL_H_C>;
	}

	private renderQuaiBanner = () => {
		return <LL_H_C className={'page-login__quai-banner'}>
			<ICONS.quai.component className={'quai-icon'}/>
			<LL_V_L className={'header'}>
				<div className={'header__title'}>Knowledge</div>
				<div className={'header__sub-title'}>Manager</div>
			</LL_V_L>
		</LL_H_C>;
	};
}