/*
 * A typescript & react boilerplate with api call example
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from 'react';
import {
	AppPage,
	ATS_ActionProcessor,
	ATS_CollectionUpgrades,
	ATS_CrudOperations,
	ATS_ProtoComponent,
	ATS_ProtoComponentDouble,
	ATS_SyncEnvironment,
	ModuleFE_BaseApi,
	ModuleFE_Thunderstorm,
	TS_AppTools,
	TS_Route
} from '@nu-art/thunderstorm/frontend';
import {playgroundScreens} from '../../playground';
import {KnowledgeManagerOverlay} from '../../knowledge-manager/Page_KnowledgeManager/Page_KnowledgeManager';
import {ATS_DevPreps} from '../../dev-screens/dev-prep/DevPage_DevPreps';
import {ATS_SnomedDictionaryActions} from '../../dev-screens/snomed-dictionary/DevPage_SnomedDictionaryActions';
import {ATS_AccountEditor} from '@nu-art/user-account/frontend/_ats/account-editor/ATS_AccountEditor';
import {ATS_ComponentPermissionKeys, ATS_Permissions, RoutePermissions} from '@nu-art/permissions/frontend';
import {Outlet} from 'react-router-dom';
import {ATS_SessionData} from '@nu-art/user-account/frontend/_ats_session-data/ATS_SessionData';
import {ATS_Toaster} from '@nu-art/thunderstorm/frontend/_ats/ATS_Toaster';
import {ATS_PushPubSub} from '@nu-art/push-pub-sub/frontend/ui/ATS_PushPubSub';
import {PermissionKeyFE_DeveloperViewer} from '@nu-art/permissions/frontend/core/permission-keys';
import {Component_AccountThumbnail, ModuleFE_Account, PopUp_AccountMenu} from '@nu-art/user-account/frontend';
import {Component_UserDetailsEditor} from '@km/components/Component_Header/Component_UserDetailsEditor/Component_UserDetailsEditor';
import {ATS_EditableItemTesting} from '@nu-art/thunderstorm/frontend/_ats/ATS_EditableItem/ATS_EditableItem';
import {ATS_ExpressionBuildingUtils} from '@app/hcs/frontend/ui/expressions/ui';
import {ATS_ExpressionBuilder} from '@app/hcs/frontend/ui/expressions/ui/playground/builder-playground/ATS_ExpressionBuilder';
import {ATS_ShortUrl} from '@nu-art/ts-short-url/frontend/_ats';
import {ATS_DependencyViewer} from '@nu-art/ts-dependency-viewer/frontend/_ats';
import {ATS_SlackMessageBuilder} from '@nu-art/slack/frontend/_ats/ATS_SlackMessageBuilder/ATS_SlackMessageBuilder';
import {ModuleFE_User} from '@app/org/_entity/user/frontend';
import {ATS_IDBCacheComparison} from '@nu-art/thunderstorm/frontend/_ats/ATS_IDBCacheComparison/ATS_IDBCacheComparison';
import {ATSGroup_ConflictResolution} from '@nu-art/conflict-resolution/frontend/_ats';

const screens = [
	...playgroundScreens,
	ATS_Permissions.screen,
	ATS_Toaster.screen,
	ATS_AccountEditor.screen,
	ATS_ActionProcessor.screen,
	ATS_CollectionUpgrades.screen,
	...ATSGroup_ConflictResolution,
	ATS_CrudOperations.screen,
	ATS_EditableItemTesting.screen,
	ATS_SyncEnvironment.screen,
	ATS_ExpressionBuildingUtils.screen,
	ATS_ExpressionBuilder.screen,
	ATS_PushPubSub.screen,
	ATS_DevPreps,
	ATS_SnomedDictionaryActions,
	ATS_ComponentPermissionKeys.screen,
	ATS_SessionData.screen,
	ATS_ProtoComponent.screen,
	ATS_ProtoComponentDouble.screen,
	ATS_ShortUrl.screen,
	ATS_DependencyViewer.screen,
	ATS_SlackMessageBuilder.screen,
	ATS_IDBCacheComparison.screen,
];

export class Page_Main
	extends AppPage {

	static renderAccountThumbnail = () => {
		const user = ModuleFE_User.getUser();
		if (!user)
			return;

		return <Component_AccountThumbnail
			accountId={() => user._id}
			modulesToAwait={[ModuleFE_User, ModuleFE_Account] as unknown as ModuleFE_BaseApi<any>[]}
			onClick={e => {
				PopUp_AccountMenu.show(e, {
					accountId: user._id,

					menuActions: [
						PopUp_AccountMenu.Action_EditPassword,
						{
							label: 'Edit My Details',
							type: 'page',
							pageKey: 'edit-my-details',
							content: (account, trigger) => <Component_UserDetailsEditor account={account} onSubmitCompleted={trigger}/>
						},
						{
							label: 'Go To KM',
							type: 'action',
							closePopUp: true,
							action: () => {
								ModuleFE_Thunderstorm.openUrl('/km', '_blank');
							}
						}
					]
				});
			}}
		/>;
	};

	static Route: TS_Route = {
		path: '/',
		key: 'root',
		Component: this,
		fallback: true,
		children: [
			KnowledgeManagerOverlay.Route,
			{
				...TS_AppTools.createRoute(screens, 'app-tools', Page_Main.renderAccountThumbnail),
				enabled: RoutePermissions(PermissionKeyFE_DeveloperViewer)
			},
		]
	};

	constructor(props: {}) {
		super(props);
	}

	protected deriveStateFromProps(nextProps: {}) {
		return {};
	}

	render() {
		return <Outlet/>;
	}
}