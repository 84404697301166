import * as React from 'react';
import {
	AppToolsScreen,
	ComponentSync,
	DialogButton,
	ModuleFE_Dialog,
	ModuleFE_Toaster,
	Props_TSDialog,
	Button,
	TS_Checkbox,
	TS_Dialog
} from '@nu-art/thunderstorm/frontend';
import {functionThatReturnsTrue, sleep} from '@nu-art/ts-common';
import {ICONS} from '@app/styles';

type Props = Props_TSDialog & {}

type State = {
	checkState: boolean;
	dialogIsBusy?: boolean;
}

class Dialog_TestDialog
	extends TS_Dialog<Props, State> {

	// ######################## Static ########################

	static defaultProps = {
		dialogId: 'test-dialog',
	};

	static show() {
		ModuleFE_Dialog.show({
				content:
					<Dialog_TestDialog/>,
				closeOverlayOnClick: functionThatReturnsTrue
			}
		);
	}

	// ######################## Life Cycle ########################

	protected deriveStateFromProps(nextProps: {}, state?: Partial<State>) {
		if (!state)
			state = this.state ? {...this.state} : {} as State;
		state.checkState ??= false;
		return state as State;
	}

	// ######################## Logic ########################

	private toaster = (str: string) => {
		ModuleFE_Toaster.toastSuccess(str);
	};

	// ######################## Render ########################


	protected dialogLeftButtons = (): DialogButton[] => {
		return [
			{
				content: 'Delete',
				className: 'delete-button',
				onClick: () => this.toaster('Delete'),
				associatedKeys: ['Backspace']
			},
		];
	};

	protected dialogRightButtons = (): DialogButton[] => {
		return [
			{content: 'Cancel', onClick: () => this.closeDialog()},
			{
				content: 'Save',
				associatedKeys: ['Enter'],
				onClick: async () => {
					await sleep(1000);
					this.toaster('Save');
					console.log(this.state);
				}
			},
		];
	};

	protected renderHeader = () => {
		return <>
			<div className={'header__title'}>Test Dialog</div>
			<ICONS.x.component onClick={() => this.closeDialog()}/>
		</>;
	};

	protected renderBody = () => {
		return <TS_Checkbox
			checked={this.state.checkState}
			onCheck={() => {
				this.setState({checkState: !this.state.checkState});
			}}>Test</TS_Checkbox>;
	};
}

class PgDev_Dialog_Class extends ComponentSync {

	protected deriveStateFromProps(nextProps: any, state?: Partial<any> | undefined) {
	}

	render() {
		return <>
			<Button onClick={() => Dialog_TestDialog.show()}>
				Open Test Dialog
			</Button>
		</>;
	}
}

export const PgDev_Dialog: AppToolsScreen = {name: 'Dialog', key: 'dialog', renderer: PgDev_Dialog_Class};