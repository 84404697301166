import * as React from 'react';
import {AppToolsScreen, ComponentSync, LL_V_L, ModuleFE_ActionProcessor, Button} from '@nu-art/thunderstorm/frontend';

class PgComponent
	extends ComponentSync {

	render() {
		return <LL_V_L>
			{this.renderAppConfigDiseaseViewsPreparationButton()}
		</LL_V_L>;
	}

	private renderAppConfigDiseaseViewsPreparationButton = () => {
		return <Button onClick={this.createMissingDiseaseViewTags}>Create Missing Disease View Tags</Button>;
	};

	private createMissingDiseaseViewTags = async () => {
		await ModuleFE_ActionProcessor.vv1.execute({key: 'introduce-and-apply--dp-view-config-tags'}).executeSync();
	};
}

export const ATS_DevPreps: AppToolsScreen = {
	name: 'Dev Preparations',
	key: 'dev-preparations',
	renderer: PgComponent,
	group: 'Quai Dev Tools',
};