import * as React from 'react';
import {AppToolsScreen, ComponentSync, Button, TS_ComponentTransition} from '@nu-art/thunderstorm/frontend';
import './PgDev_CssTransitions.scss';

type State = {
	showTransitionItem: boolean;
};

class PgDev_CssTransitions_Class
	extends ComponentSync<{}, State> {

	protected deriveStateFromProps(nextProps: any): State {
		return {
			showTransitionItem: false,
		};
	}

	private toggleShowTransitionItem = () => {
		this.setState({showTransitionItem: !this.state.showTransitionItem});
	};

	render() {
		return <div className={'pg-dev-css-transitions'}>
			<Button onClick={this.toggleShowTransitionItem}>Toggle Show Transition Item</Button>
			<TS_ComponentTransition trigger={this.state.showTransitionItem} mountTimeout={100} transitionTimeout={500} unmountTimeout={100}>
				<div className={'transition-item'}>Transition Item</div>
			</TS_ComponentTransition>
		</div>;
	}
}

export const PgDev_CssTransitions: AppToolsScreen = {name: 'CssTransitions', key: 'css-transitions', renderer: PgDev_CssTransitions_Class};