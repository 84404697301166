import * as React from 'react';
import {EditableDBItemV3, ModuleFE_Dialog, Props_TSDialog, ToastBuilder, TS_Dialog} from '@nu-art/thunderstorm/frontend';
import {ICONS} from '@app/styles';
import {DB_Resource} from '@app/hcs';
import {ModuleFE_Resource} from '@app/hcs/frontend';
import './ResourcesManager_Dialogs.scss';
import {ResourceItem_EditorRenderer} from '@km/managers/ResourceEditor/editors/Editor-renderers/ResourceItem_EditorRenderer';
import {_keys, capitalizeFirstLetter} from '@nu-art/ts-common';

type Props = Props_TSDialog & {
	onCompleted: (item: DB_Resource) => void,
}


type State = {
	dialogIsBusy: boolean,
	resourceItem: Partial<DB_Resource>
}

export class Dialog_CreateNewResource
	extends TS_Dialog<Props, State> {

	static defaultProps = {
		dialogId: 'create-new-order-dialog',
	};

	static show(onCompleted: (item: DB_Resource) => void) {
		ModuleFE_Dialog.show({content:
			<Dialog_CreateNewResource className={'dialog'} onCompleted={onCompleted}/>,
			closeOverlayOnClick:() => false});
	}


	// ######################## Life Cycle ########################sc

	protected deriveStateFromProps(nextProps: Props): State {
		return {dialogIsBusy: false, resourceItem: {}};
	}

	// ######################## Logic ########################

	protected closeDialog = () => {
		if (!this.state.dialogIsBusy)
			ModuleFE_Dialog.close();
	};

	private execute = async () => {
		this.logWarning(this.state.resourceItem);

		const newResource = new EditableDBItemV3(this.state.resourceItem, ModuleFE_Resource, (e: Error) => {
			throw e;
		});
		this.setState({dialogIsBusy: true});
		try {
			await newResource.save();
			ModuleFE_Dialog.close();
		} catch (err: any) {
			this.setState({dialogIsBusy: false});
			new ToastBuilder().setContent(<div>
 				 <span className={'toast-text'}>
				  {`Missing Fields: ${_keys(err.result).map((item) => capitalizeFirstLetter(item as string)).join(', ')}`}
</span>
			</div>).setDuration(4000).show();
			this.logError(err);
		}
	};

	// ######################## Render ########################

	protected renderHeader = () => {
		return <>
			<span className="header__title">Create New Resource</span>
			<div className={'header__buttons'}>
				<ICONS.x.component className="exit_icon" onClick={this.closeDialog}/>
			</div>
		</>;
	};

	protected renderBody = () => {
		return <ResourceItem_EditorRenderer
			editable={new EditableDBItemV3(this.state.resourceItem, ModuleFE_Resource)}
			isInEditMode={true}
		/>;
	};

	protected buttons = () => {
		return {
			right: [
				{content: 'Cancel', className: 'cancel-button', onClick: this.closeDialog},
				{
					content: 'Save Resource',
					onClick: this.execute,
					className: 'desired-action-button',
					associatedKeys: ['Enter'],
				}
			]
		};
	};
}