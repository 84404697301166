import * as React from 'react';
import {ComponentSync, LL_H_C, LL_V_L, ModuleFE_WindowMessenger, Button, TS_Input, Messenger, AppToolsScreen} from '@nu-art/thunderstorm/frontend';

type State = {
	key: string;
	data: any;
}

type FrameState = {
	value: string;
}

type TestMessage = {
	key: 'test';
	payload: string;
}

type TestMessage2 = {
	key: 'test2';
	payload: number;
}

class PgDev_WindowMessenger_Class
	extends ComponentSync<{}, State> {

	constructor() {
		super({});
		ModuleFE_WindowMessenger
			.createReceiver<TestMessage | TestMessage2>(window.origin)
			.addProcessor('test', (message: TestMessage) => {
				console.log('Got message with key test:', message.payload);
				this.setState({key: message.key, data: message.payload});
			})
			.addProcessor('test2', (message: TestMessage2) => {
				console.log('Got message with key test2:', message.payload);
			})
			.mount();
	}


	protected deriveStateFromProps(nextProps: any, state: State) {
		return state;
	}

	render() {
		return <LL_V_L id={'pg-dev__window-messenger'}>
			<LL_V_L>
				<LL_H_C style={{gap: '8px'}}>
					<div>Key:</div>
					<div>{this.state.key}</div>
				</LL_H_C>
				<LL_H_C style={{gap: '8px'}}>
					<div>Data:</div>
					<div>{JSON.stringify(this.state.data)}</div>
				</LL_H_C>
			</LL_V_L>
			<iframe src={'https://localhost:8100/app-tools/0aa96164cf873d175287c8300e3ad58c'} style={{width: '100%'}}/>
		</LL_V_L>;
	}
}

class FrameRenderer extends ComponentSync<{}, FrameState> {

	private messenger: Messenger<TestMessage | TestMessage2> = ModuleFE_WindowMessenger.createMessenger<TestMessage | TestMessage2>(window.parent);

	protected deriveStateFromProps(nextProps: any, state: FrameState) {
		return state;
	}

	render() {
		return <LL_V_L>
			<TS_Input
				type={'text'}
				value={this.state.value}
				onChange={value => this.setState({value})}
			/>
			<Button onClick={e => {
				this.messenger.sendMessage({key: 'test', payload: this.state.value});
			}}>Send</Button>
			<Button onClick={e => {
				this.messenger.sendMessage({key: 'test2', payload: 123});
			}}>Send2</Button>
		</LL_V_L>;
	}

}

export const PgDev_WindowMessenger: AppToolsScreen = {
	name: 'window messenger',
	key: 'window-messenger',

	renderer: PgDev_WindowMessenger_Class
};
export const PgDev_WindowMessengerFrame: AppToolsScreen = {
	name: 'window-messenger-frame',
	key: 'window-messenger-frame',

	renderer: FrameRenderer
};