import * as React from 'react';
import {
	AppToolsScreen,
	BaseAsyncState,
	ComponentAsync,
	mouseEventHandler,
	Button
} from '@nu-art/thunderstorm/frontend';
import './PgDev_GeneralTesting.scss';
import {__stringify, BadImplementationException} from '@nu-art/ts-common';
import {ModuleFE_Value, ModuleFE_Variable} from '@app/hcs/frontend';
import {
	DB_Value,
	ValueData,
	ValueType_EnumElement,
	ValueType_SubRange
} from '@app/hcs';
import {StorageKey_SessionId} from '@nu-art/user-account/frontend';
import {valueType_EnumElement, valueType_SubRange} from '@app/hcs/_entity/value/shared/consts';

type State = {
	doNotRenderIndexes: number[];
	decodedSessionData?: {};
} & BaseAsyncState

const types = [valueType_EnumElement, valueType_SubRange] as (keyof ValueData)[];

type Props = {};

class PgDev_GeneralTesting_Class
	extends ComponentAsync<Props, State> {

	protected async deriveStateFromProps(nextProps: Props, state?: State) {
		state ??= this.state ? {...this.state} : {doNotRenderIndexes: []};

		if (!state.decodedSessionData) {
			// state.decodedSessionData = ModuleFE_AccountV3.decodeSessionData();
		}

		return state;
	}

	renderContainer = (index: number) => {
		if (this.state.doNotRenderIndexes.includes(index))
			return '';

		return <div className={`isolated-container c-${index}`}>
			<div className={'isolated-container-overlay'} onClick={() => {
				console.log(index);
				this.setState({doNotRenderIndexes: [...this.state.doNotRenderIndexes, index]});
			}}/>
			{/*<div className={'isolated-container-item'}>{item}</div>*/}
		</div>;
	};

	clickHandler = (str: string) => {
		console.log(str);
	};

	private getValueLabel = (value: DB_Value<ValueType_EnumElement | ValueType_SubRange>): string => {
		if (value.type === valueType_EnumElement)
			return (value as DB_Value<ValueType_EnumElement>).data.value;

		if (value.type === valueType_SubRange)
			return (value as DB_Value<ValueType_SubRange>).data.label!;

		throw new BadImplementationException('WTF');
	};

	render() {
		return <div className={'pg-dev-general-testing'}>
			<div>Some random content</div>
			{/*{[1, 2, 3, 4].map(this.renderContainer)}*/}
			<Button onClick={() => this.setState({doNotRenderIndexes: []})}>Reset overlays</Button>
			<div
				style={{width: '100px', height: '100px', background: 'green'}}
				onMouseUp={e => mouseEventHandler(e, {
					left: () => this.clickHandler('Left'),
					middle: () => this.clickHandler('Middle'),
					right: () => this.clickHandler('Right'),
				})}
			>CLICK ME
			</div>
			<div>Session ID: <br/>{StorageKey_SessionId.get()}</div>
			<div>Decoded Session ID: <br/>{__stringify(this.state.decodedSessionData)}</div>
			<Button onClick={() => {
				console.log(ModuleFE_Variable.cache.all().map(i => i.name).sort().join('\n'));
			}}>All Variables</Button>
			<Button onClick={() => {
				// @ts-ignore
				console.log(ModuleFE_Value.cache.filter(i => types.includes(i.type)).map(this.getValueLabel).sort().join('\n'));
			}}>All Enum Values</Button>
		</div>;
	}
}

export const PgDev_GeneralTesting: AppToolsScreen = {
	name: 'General Tests',
	key: 'general-tests',
	renderer: PgDev_GeneralTesting_Class
};